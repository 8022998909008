import { ComponentPropsWithRef, FC } from 'react';
import ThematicButton from '@components/buttons/ThematicButton/ThematicButton.component';
import { Text } from '@components/Text/Text.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { urlEnum } from '@utils/url';
import classNames from 'classnames';
import styles from './ThematicContent.module.scss';

type Props = ComponentPropsWithRef<'div'>;

export const ThematicContent: FC<Props> = ({ className }) => {
  const classname = classNames(styles.content, className, 'wrapper-s plc-pb-s plc-pt-s');
  const isMobile = useIsBreakpointDown(Breakpoint.m);

  const gestionButton = (
    <ThematicButton
      classNameText={styles.text}
      fillColor={PlcColor.GREEN}
      label="thematic.header.gestion"
      url={urlEnum.thematiqueGestion}
    />
  );
  const installationButton = (
    <ThematicButton
      classNameText={styles.text}
      fillColor={PlcColor.GREEN}
      label="thematic.header.installation"
      url={urlEnum.thematiqueInstallation}
    />
  );
  const transitionButton = (
    <ThematicButton
      classNameText={styles.text}
      fillColor={PlcColor.GREEN}
      label="thematic.header.transitions"
      url={urlEnum.thematiqueTransitions}
    />
  );
  const innovationButton = (
    <ThematicButton
      classNameText={styles.text}
      fillColor={PlcColor.GREEN}
      label="thematic.header.innovation"
      url={urlEnum.innovations}
    />
  );

  return (
    <div className={classname}>
      <Text
        className={styles.title}
        i18nKey={`thematic.content.title-${isMobile ? 'mobile' : 'desktop'}`}
        variant="p-h3-upper-style"
      />
      <Text className={styles.intro} i18nKey="thematic.content.intro" variant="small" />
      {isMobile ? (
        <>
          <div className={styles.flex}>
            {gestionButton}
            {installationButton}
          </div>
          <div className={styles.flex}>
            {transitionButton}
            {innovationButton}
          </div>
        </>
      ) : (
        <div className={styles.flex}>
          {gestionButton}
          {installationButton}
          {transitionButton}
          {innovationButton}
        </div>
      )}
    </div>
  );
};
